<template>
	<el-drawer
		title="查看客户询单信息"
		:visible.sync="drawer_"
		destroy-on-close
		direction="rtl"
		custom-class="demo-drawer"
		ref="drawer"
		size="60%"
		@open="open"
		@close="
			() => {
				$emit('closeVisible');
			}
		"
	>
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<el-card class="box-card">
					<div
						style="padding: 10px"
						v-for="(item, index) in list"
						:key="index"
					>
						<div
							class="desc"
							:class="{
								activeColor: !(
									item.feedbackStateName == '已反馈'
								),
							}"
						>
							{{ item.feedbackStateName }}
						</div>
						<p
							style="
								font-size: 22px;
								font-weight: 800;
								color: #23924f;
								margin: 0 0 20px 0;
								cursor: pointer;
							"
						>
							{{ item.pdfClientName || item.clientName }}
						</p>

						<div style="font-weight: 800">客户简介</div>
						<div
							class="info"
							v-html="fn(item.recommendedzReason || '')"
						></div>
						<div style="font-weight: 800">客户需求</div>
						<div
							class="info"
							v-html="fn(item.clientRequirement || '')"
						></div>
						<div style="font-weight: 800">注意事项</div>
						<div
							class="info"
							v-html="fn(item.noticeMatter || '')"
						></div>
						<div style="font-weight: 800">咨询问题</div>
						<div
							class="info"
							v-html="fn(item.askQuestions || '')"
						></div>
						<div style="font-weight: 800">备注</div>
						<div class="info" v-html="fn(item.remark || '')"></div>
						<div
							style="font-weight: 800"
							v-if="item.inquiryAttachment.length"
						>
							附件
						</div>
						<el-upload
							:headers="{
								token: $store.state.userInfo.token,
							}"
							:on-preview="handelOnChange"
							class="upload-demo"
							name="files"
							:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
							multiple
							:limit="3"
							:file-list="item.inquiryAttachment"
						>
						</el-upload>
						<div style="display: flex">
							<div style="flex: 1" v-if="item.audio">
								<div style="font-weight: 800">音频信息</div>
								<div name="showVideo" style="float: left">
									<div
										v-for="(
											item, index
										) in item.audio.split(',')"
										:key="index"
										id="video0"
										style="float: left; margin: 10px"
									>
										<audio
											preload="metadata"
											controls=""
											width="320"
											height="240"
										>
											<source
												name="audioUrl"
												:src="item"
												type="audio/mpeg"
											/>
											您的浏览器不支持 audio 元素。
										</audio>
									</div>
								</div>
							</div>
						</div>
						<div style="display: flex">
							<div style="flex: 1" v-if="item.video">
								<div style="font-weight: 800">视频信息</div>
								<div name="showVideo" style="float: left">
									<div
										v-for="(
											item, index
										) in item.video.split(',')"
										:key="index"
										id="video0"
										style="float: left; margin: 10px"
									>
										<video
											preload="metadata"
											width="240"
											height="100"
											controls=""
										>
											<source
												preload="metadata"
												name="videoUrl"
												:src="item"
												type="video/mp4"
											/>
											您的浏览器不支持Video标签。
										</video>
									</div>
								</div>
							</div>
						</div>

						<div
							@click="$emit('isShowDialogVisible', item)"
							v-if="item.clientId"
							style="
								margin-bottom: 30px;
								margin-top: 20px;
								font-weight: 800;
								font-size: 20px;
								color: red;
								cursor: pointer;
							"
						>
							点击查看客户详情
						</div>
						<div style="display: flex; margin-bottom: 30px">
							<div style="margin-right: 100px">
								<span style="font-weight: 800">方案经理</span>
								{{ item.productManagerName || '' }}
							</div>
							<div>
								<span style="font-weight: 800">联系电话</span>
								{{ item.productManagerPhone || '' }}
							</div>
						</div>
						<div
							style="font-weight: 800"
							v-if="item.enquiryResultsName"
						>
							询单结果
						</div>
						<div
							class="info"
							v-if="item.enquiryResultsName"
							v-html="fn(item.enquiryResultsName || '')"
						></div>
						<div v-if="item.feedbackInfo">
							<div style="font-weight: 800">
								我反馈给悦融易的信息
							</div>
							<div
								class="info"
								v-html="fn(item.feedbackInfo || '')"
							></div>
						</div>
						<div style="padding: 15px 0 0 0; text-align: right">
							<el-popover
								@show="() => show(item)"
								placement="top"
								width="600"
								trigger="click"
								v-if="item.feedbackStateName == '待反馈'"
								v-model="item.visible"
							>
								<div class="replyinfo">
									<p>请输入快捷回复信息</p>
									<el-radio-group
										style="padding: 15px 0px"
										v-model="replyinfo.enquiryResultsId"
									>
										<el-radio
											v-for="(
												item, index
											) in EnquiryResultsList"
											:key="index"
											:label="item.value"
											>{{ item.label }}</el-radio
										>
									</el-radio-group>
									<el-input
										type="textarea"
										:rows="6"
										v-model="replyinfo.feedbackInfo"
										placeholder="请输入内容"
									>
									</el-input>
									<div class="replyinfo-btn">
										<el-button @click="item.visible = false"
											>取消</el-button
										>
										<el-button
											@click="
												postClientInquiryReply(item)
											"
											type="primary"
											>回复</el-button
										>
									</div>
								</div>
								<el-button
									type="primary"
									style="width: 20%"
									slot="reference"
									>快捷回复</el-button
								>
							</el-popover>
						</div>
					</div>
				</el-card>
				<div class="demo-drawer__footer">
					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import {
	clientInquiryList,
	clientInquiryReply,
	itemByType,
} from '@/api/index.js';

export default {
	name: 'inquiryDrawer',

	props: {
		inquiryVisible: {
			type: Boolean,
			required: true,
		},
		paramsId: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			list: [],
			ruleForm: {
				clientName: '',
				cooperationPresidentId:
					this.$store.state.userInfo.userVo.cooperationPresidentId,
				startTime: '',
				endTime: '',
				feedbackState: '',
				pageSize: 10,
				pageNo: 1,
				sortFlag: 'pushTime-desc',
			},
			replyinfo: {
				inquiryId: '',
				feedbackInfo: '',
				enquiryResultsId: '',
			},
			EnquiryResultsList: [],
		};
	},
	methods: {
		handelOnChange(res) {
			!/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(res.url)
				? (location.href =
						this.$baseUrl +
						'api/file/fileDownloadCommon.do?path=' +
						res.url)
				: window.open(res.url);
		},
		fn(str = '') {
			return str.replace(/\n/g, '<br />');
		},
		show(e) {
			this.replyinfo.feedbackInfo = e.feedbackInfo;
			this.replyinfo.enquiryResultsId = e.enquiryResultsId;
			this.replyinfo.inquiryId = e.inquiryId;
		},
		open() {
			this.getClientInquiryList();
			this.getEnquiryResults();
		},
		async getEnquiryResults() {
			const res = await itemByType({
				dictionaryType: 'EnquiryResults',
			});
			if (res.returncode === 0) {
				this.EnquiryResultsList = res.data.map((item) => ({
					value: Number(item.itemValue),
					label: item.itemName,
				}));
			}
		},
		async getClientInquiryList() {
			this.ruleForm.inquiryId =
				this.paramsId.refId || this.paramsId.inquiryId;
			const res = await clientInquiryList(this.ruleForm);
			res.list.forEach((element) => {
				element.visible = false;

				if (element.inquiryAttachment) {
					element.inquiryAttachment = JSON.parse(
						element.inquiryAttachment
					).map((citem) => ({
						name: citem.fileName,
						url: !/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(citem.ossUrl)
							? citem.filePath
							: citem.ossUrl,
					}));
				} else {
					element.inquiryAttachment = [];
				}
			});
			if (res.returncode == 0) {
				this.list = res.list;
			}
		},
		async postClientInquiryReply(data) {
			if (!this.replyinfo.enquiryResultsId) {
				return this.$message({
					message: '请选择单选框',
					type: 'warning',
				});
			}
			if (!this.replyinfo.feedbackInfo) {
				return this.$message({
					message: '请输入回复内容',
					type: 'warning',
				});
			}
			this.replyinfo.inquiryId = data.inquiryId;
			const res = await clientInquiryReply(this.replyinfo);
			if (res.returncode == 0) {
				this.getClientInquiryList();
				const d = this.EnquiryResultsList.find(
					(item) => item.value == this.replyinfo.enquiryResultsId
				);
				data.enquiryResultsName = d.label;

				data.feedbackInfo = this.replyinfo.feedbackInfo;
				data.visible = false;
				data.feedbackStateName = '已反馈';
				this.replyinfo.feedbackInfo = '';
				this.replyinfo.enquiryResultsId = '';

				this.$message({
					message: '回复成功',
					type: 'success',
				});
			}
		},
	},
	created() {},
	computed: {
		drawer_: {
			get() {
				return this.inquiryVisible;
			},
			set(v) {
				this.$emit('update:inquiryVisible', v);
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.box-card {
	margin-bottom: 10px;
}
::v-deep .el-upload-list__item > label {
	display: none !important;
}
::v-deep .el-upload-list__item > i {
	display: none !important;
}
::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;
	.el-button {
		width: 15%;
	}
}

.inquiry {
	width: 100%;
	background: #fff;
	overflow: auto;
	position: relative;
}
.info {
	line-height: 20px;
	margin: 10px 15px 20px 15px;
}
.desc {
	position: absolute;
	top: -53px;
	right: -78px;
	z-index: 5;
	color: #fff;
	background: #409eff;
	display: block;
	transform: rotateZ(45deg);
	width: 180px;
	transform-origin: bottom left;
	height: 30px;
	text-align: center;
	line-height: 30px;
}
.activeColor {
	background: #f64c4c;
}
.box-card {
	position: relative;
}
.replyinfo {
	p {
		font-size: 14px;
		font-weight: 800;
		margin-bottom: 10px;
	}

	.replyinfo-btn {
		margin-top: 10px;
		display: flex;
		justify-content: flex-end;
	}
}
</style>
