<template>
	<el-drawer
		:close-on-press-escape="false"
		:wrapperClosable="false"
		title="推荐客户给悦融易"
		:visible.sync="drawer_"
		direction="rtl"
		custom-class="demo-drawer"
		ref="drawer"
		size="70%"
		@close="
			() => {
				$refs['ruleForm'].resetFields();
				Object.assign($data, $options.data());
			}
		"
	>
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<el-form
					:model="ruleForm"
					status-icon
					ref="ruleForm"
					class="demo-ruleForm"
					label-position="rigth"
					label-width="120px"
				>
					<el-form-item
						label="客户名称:"
						:rules="[
							{
								required: true,
								message: '客户名称不能为空',
							},
						]"
						prop="clientName"
					>
						<el-input
							placeholder="请输入客户名称"
							v-model="ruleForm.clientName"
						></el-input>
					</el-form-item>
					<el-form-item
						:rules="[
							{
								required: true,
								message: '联系人不能为空',
							},
						]"
						label="联系人:"
						prop="linkMan"
					>
						<el-input
							placeholder="请输入联系人"
							v-model="ruleForm.linkMan"
							maxlength="20"
						></el-input>
					</el-form-item>

					<el-form-item
						:rules="[
							{
								required: true,
								message: '联系电话不能为空',
								trigger: 'blur',
							},

							{
								pattern: /^1[345789]\d{9}$/,
								message: '请输入正确的联系电话',
							},
						]"
						label="联系电话:"
						prop="mobilePhone"
					>
						<el-input
							placeholder="请输入联系电话"
							v-model.trim="ruleForm.mobilePhone"
						></el-input>
					</el-form-item>

					<el-form-item
						prop="financingIntention"
						label="融资意向:"
						:rules="[
							{
								required: true,
								message: '融资意向不能为空',
							},
						]"
					>
						<el-input
							type="textarea"
							:rows="5"
							placeholder="请输入内容"
							v-model="ruleForm.financingIntention"
						>
						</el-input>
					</el-form-item>
					<el-form-item label="客户简介:">
						<el-input
							type="textarea"
							:rows="5"
							placeholder="请输入内容"
							v-model="ruleForm.recommendReason"
						>
						</el-input>
					</el-form-item>
					<el-form-item label="关注问题:">
						<el-input
							type="textarea"
							:rows="5"
							placeholder="请输入内容"
							v-model="ruleForm.concernedProblems"
						>
						</el-input>
					</el-form-item>
					<el-form-item label="备注:">
						<el-input
							type="textarea"
							:rows="5"
							placeholder="请输入备注"
							v-model="ruleForm.bankRemark"
						>
						</el-input>
					</el-form-item>
				</el-form>
				<div class="demo-drawer__footer">
					<el-button type="primary" @click="postRecommendClientSave"
						>提 交</el-button
					>

					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import { recommendClientSave } from '@/api/index.js';

export default {
	name: 'infoDrawer',
	props: {
		dialogVisible: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			ruleForm: {
				clientName: '',
				linkMan: '',
				mobilePhone: '',
				financingIntention: '',
				recommendReason: '',
				concernedProblems: '',
				bankRemark: '',
			},
		};
	},
	methods: {
		postRecommendClientSave() {
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					const res = await recommendClientSave(this.ruleForm);
					if (res.returncode == 0) {
						this.$message({
							message: '提交成功',
							type: 'success',
						});
						this.$emit('closeVisible', true);
					}
				}
			});
		},
	},
	created() {},
	computed: {
		drawer_: {
			get() {
				return this.dialogVisible;
			},
			set(v) {
				this.$emit('update:dialogVisible', v);
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.box-card {
	margin-bottom: 10px;
}
::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;
	.el-button {
		width: 15%;
	}
}
::v-deep .el-drawer__header {
	padding-bottom: 20px;
	border-bottom: 1px solid #dee1e7;
}
</style>
