<template>
	<div class="mytable">
		<div class="my-title">
			<p>首页</p>
		</div>

		<div class="menu">
			<p>快捷菜单</p>
			<div class="menu-box" style="display: flex">
				<span style="cursor: pointer" @click="dialogVisible = true">
					<div>推荐客户给</div>
					<div>悦融易</div>
				</span>
				<span style="cursor: pointer" @click="$router.push('/index/dataanalysis')">
					<div>银行流水</div>
					<div>分析工具</div>
				</span>
				<span style="cursor: pointer" @click="$router.push('/index/financial')">
					<div>财务数据</div>
					<div>分析工具</div>
				</span>
			</div>
		</div>
		<div class="model-box">
			<div>
				<p>悦融易推荐的10个最新客户</p>
				<!-- table 列表 -->
				<el-table height="77%" style="margin: 10px 0" :data="mycustomerData" border>
					<el-table-column align="left" label="客户名称">
						<template slot-scope="scope">
							<div style="padding-top: 8px">
								<el-badge v-if="scope.row.dealStatus == 1 ||
									scope.row.dealStatus == 2
									" value="需要我反馈" class="item">
									<div class="blue" @click="
										showDialogVisible(
											scope.row,
											'customerDialogVisible',
											3
										)
										" style="cursor: pointer">
										{{
											scope.row.whetherSensitive == 1
											? scope.row.pdfClientName
											: scope.row.clientName
										}}
									</div>
								</el-badge>
								<div v-else class="blue" @click="
								showDialogVisible(
									scope.row,
									'customerDialogVisible',
									3
								)
									" style="cursor: pointer">
									{{
										scope.row.whetherSensitive == 1
										? scope.row.pdfClientName
										: scope.row.clientName
									}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column width="150" align="left" prop="" label="处理状态">
						<template slot-scope="scope">
							<div>
								{{ scope.row.dealStatusName }}
							</div>
						</template>
					</el-table-column>
					<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
				</el-table>
			</div>
			<div>
				<p style="display: flex; justify-content: space-between">
					<span>待处理询单</span>
					<span @click="$router.push('/index/inquiry?state=1')" style="color: #02a7f0; cursor: pointer">
						去反馈
					</span>
				</p>
				<!-- table 列表 -->
				<el-table height="77%" style="margin: 10px 0" :data="inquiryData" border>
					<el-table-column align="left" label="名称">
						<template slot-scope="scope">
							<div @click="
								showDialogVisible(
									scope.row,
									'inquiryVisible',
									1
								)
								" style="color: #02a7f0; cursor: pointer">
								{{
									scope.row.pdfClientName ||
									scope.row.clientName
								}}
							</div>
						</template>
					</el-table-column>
					<el-table-column align="left" prop="feedbackStateName" label="状态" width="150">
					</el-table-column>
					<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
				</el-table>
				<div style="text-align: end">
					<el-pagination @size-change="(val) =>
						handleSizeChange(
							val,
							'inquiryParams',
							'getClientInquiryList'
						)
						" @current-change="(val) =>
		handleCurrentChange(
			val,
			'inquiryParams',
			'getClientInquiryList'
		)
		" :current-page="inquiryParams.pageNo" :page-sizes="[5, 10, 20, 30, 50]" :page-size="10"
						layout="total, sizes, prev, pager, next, jumper" :total="inquiryParams.total">
					</el-pagination>
				</div>
			</div>

			<div>
				<p>客户未完结方案</p>
				<!-- table 列表 -->
				<el-table height="77%" style="margin: 10px 0" :data="schemeData" border>
					<el-table-column align="left" label="客户名称">
						<template slot-scope="scope">
							<div @click="
								showDialogVisible(
									scope.row,
									'schemeVisible',
									4
								)
								" style="color: #02a7f0; cursor: pointer">
								{{ scope.row.clientName }}
							</div>
						</template>
					</el-table-column>
					<el-table-column align="left" prop="financialProductName" label="产品名称">
					</el-table-column>
					<el-table-column align="left" prop="schemeLinkName" label="当前环节">
					</el-table-column>
					<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
				</el-table>
				<div style="text-align: end">
					<el-pagination @size-change="(val) =>
						handleSizeChange(
							val,
							'schemeParams',
							'getSchemeInfoList'
						)
						" @current-change="(val) =>
		handleCurrentChange(
			val,
			'schemeParams',
			'getSchemeInfoList'
		)
		" :current-page="schemeParams.pageNo" :page-sizes="[5, 10, 20, 30, 50]" :page-size="10"
						layout="total, sizes, prev, pager, next, jumper" :total="schemeParams.total">
					</el-pagination>
				</div>
			</div>
			<div>
				<p>客户未完结批复</p>
				<!-- table 列表 -->
				<el-table height="77%" style="margin: 10px 0" :data="approvalData" border>
					<el-table-column align="left" label="客户名称">
						<template slot-scope="scope">
							<div @click="
								showDialogVisible(
									scope.row,
									'approvalVisible',
									5
								)
								" style="color: #02a7f0; cursor: pointer">
								{{ scope.row.clientName }}
							</div>
						</template>
					</el-table-column>
					<el-table-column align="left" prop="financialProductName" label="产品名称">
					</el-table-column>
					<el-table-column align="left" prop="nextApprovalLinkName" label="当前环节">
					</el-table-column>
					<el-empty slot="empty" description="没有找到符合条件的数据"></el-empty>
				</el-table>
				<div style="text-align: end">
					<el-pagination @size-change="(val) =>
						handleSizeChange(
							val,
							'approvalParams',
							'getSchemeInfoList'
						)
						" @current-change="(val) =>
		handleCurrentChange(
			val,
			'approvalParams',
			'getSchemeInfoList'
		)
		" :current-page="approvalParams.pageNo" :page-sizes="[5, 10, 20, 30, 50]" :page-size="10"
						layout="total, sizes, prev, pager, next, jumper" :total="approvalParams.total">
					</el-pagination>
				</div>
			</div>
		</div>
		<infoDrawer @closeVisible="dialogVisible = false" :dialogVisible.sync="dialogVisible" />
		<publicInfoDrawer :allData="allData" :privacyType="privacyType" :dialogVisible.sync="publicDialogVisible"
			:paramsId="paramsId" @closeVisible="publicDialogVisible = false" />
		<inquiryDrawer :inquiryVisible="inquiryVisible" :paramsId="paramsId" @closeVisible="inquiryVisible = false"
			@isShowDialogVisible="isShowDialogVisible" />
		<customerInfoDrawer :PresidentCooperateWillingness="PresidentCooperateWillingness"
			:CompliesCreditProductPolicy="CompliesCreditProductPolicy" :InterestedProvidingFinanceSolutions="InterestedProvidingFinanceSolutions
				" :allData="allData" :dialogVisible.sync="customerDialogVisible" :paramsId="paramsId" :privacyType="privacyType"
			@closeVisible="() => {
				customerDialogVisible = false;
				getClientListOfPresident();
			}
				" />
		<schemeDrawer @changeList="changeList" @replaceList="replaceList" @closeVisible="schemeVisible = false"
			:schemeVisible.sync="schemeVisible" :item="schemeObj" />
		<approvalDrawer @changeList="approvaChangeList" @replaceList="approvaReplaceList"
			@closeVisible="approvalVisible = false" :approvalVisible.sync="approvalVisible" :item="approvaObj" />
	</div>
</template>
<script>
import infoDrawer from '../companycustomer/module/infoDrawer.vue';
import customerInfoDrawer from '../mycustomer/module/infoDrawer.vue';
import inquiryDrawer from '../notification/module/inquiryDrawer.vue';
import schemeDrawer from './model/schemeDrawer.vue';
import approvalDrawer from './model/approvalDrawer.vue';
import {
	clientInquiryList,
	clientListOfPresident,
	schemeInfoList,
	schemeApprovalInfoList,
	getClientInfo,
	getSensitiveClientInfo,
	getClientInfoSensitive,
	itemByType,
	getSensitiveClientInfoForInquiry,
} from '@/api/index.js';
export default {
	name: 'Index',
	data() {
		return {
			PresidentCooperateWillingness: [],
			CompliesCreditProductPolicy: [],
			InterestedProvidingFinanceSolutions: [],
			allData: {},
			customerDialogVisible: false,
			publicDialogVisible: false,
			inquiryVisible: false,
			schemeVisible: false,
			approvalVisible: false,
			schemeObj: {},
			approvaObj: {},
			paramsId: {
				cooperationPresidentId:
					this.$store.state.userInfo.userVo.cooperationPresidentId,
			},
			dialogVisible: false,
			inquiryData: [],
			mycustomerData: [],
			schemeData: [],
			approvalData: [],
			inquiryParams: {
				cooperationPresidentId:
					this.$store.state.userInfo.userVo.cooperationPresidentId,
				feedbackState: '0',
				pageSize: 10,
				pageNo: 1,
				sortFlag: 'pushTime-desc',
				total: 0,
			},
			mycustomerParams: { pageSize: 10, pageNo: 1, total: 0 },
			schemeParams: {
				pageSize: 10,
				pageNo: 1,
				total: 0,
				schemeStatus: 101,
			},
			approvalParams: {
				pageSize: 10,
				pageNo: 1,
				total: 0,
				schemeApprovalStatus: 100,
			},
			privacyType: 1,
		};
	},
	methods: {
		pshowDialogVisible(row, boole = false, inquiry = false) {
			this.publicDialogVisible = true;
			this.paramsId.clientId = row.clientId || row.refId;
			this.paramsId.dealStatus = row.dealStatus || '';
			this.paramsId.clientPresidentRelatedId =
				row.clientPresidentRelatedId || '';
			this.privacyType = boole ? 1 : 2;
			this.ptriggerGetClientInfo(boole, inquiry);
		},
		async ptriggerGetClientInfo(boole, inquiry) {
			// if (boole) {
			//   this.allData = await getSensitiveClientInfo(this.paramsId);

			// } else {
			//   this.allData = await getClientInfo(this.paramsId);

			// }

			let res = {};
			if (boole && !inquiry) {
				res = await getSensitiveClientInfo(this.paramsId);
			} else if (boole && inquiry) {
				res = await getSensitiveClientInfoForInquiry(this.paramsId);
				if (!res.financeRequirementList) {
					res.financeRequirementList = [];
				}
			} else if (!boole) {
				res = await getClientInfo(this.paramsId);
			}
			if (res.clientBase.businessInformationAttachment) {
				res.clientBase.businessInformationAttachment = JSON.parse(
					res.clientBase.businessInformationAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientBase.capitalizeEnterpriseCreditAttachment) {
				res.clientBase.capitalizeEnterpriseCreditAttachment = JSON.parse(
					res.clientBase.capitalizeEnterpriseCreditAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.personsList.length) {
				res.personsList.forEach((element) => {
					if (element.controlPersonAttachment) {
						element.controlPersonAttachment = JSON.parse(
							element.controlPersonAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
					if (element.personalCreditAttachment) {
						element.personalCreditAttachment = JSON.parse(
							element.personalCreditAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
					if (element.otherAttachment) {
						element.otherAttachment = JSON.parse(
							element.otherAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}
			if (res.clientBase.capitalizeAttachment) {
				res.clientBase.capitalizeAttachment = JSON.parse(
					res.clientBase.capitalizeAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.personalAssetsList.length) {
				res.personalAssetsList.forEach((element) => {
					if (element.assetAttachment) {
						element.assetAttachment = JSON.parse(
							element.assetAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}
			if (res.capitalizesList.length) {
				res.capitalizesList.forEach((element) => {
					if (element.guaranteeAttachment) {
						element.guaranteeAttachment = JSON.parse(
							element.guaranteeAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}

			if (res.clientFinanceVO.balanceSheetAttachment) {
				res.clientFinanceVO.balanceSheetAttachment = JSON.parse(
					res.clientFinanceVO.balanceSheetAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientFinanceVO.cashFlowStatementAttachment) {
				res.clientFinanceVO.cashFlowStatementAttachment = JSON.parse(
					res.clientFinanceVO.cashFlowStatementAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientFinanceVO.incomeStatementAttachment) {
				res.clientFinanceVO.incomeStatementAttachment = JSON.parse(
					res.clientFinanceVO.incomeStatementAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientFinanceVO.taxPaymentCertificateAttachment) {
				res.clientFinanceVO.taxPaymentCertificateAttachment =
					JSON.parse(
						res.clientFinanceVO.taxPaymentCertificateAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
			}
			if (res.clientFinanceVO.taxReturnsAttachment) {
				res.clientFinanceVO.taxReturnsAttachment = JSON.parse(
					res.clientFinanceVO.taxReturnsAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (
				res.financeRequirementList &&
				res.financeRequirementList.length
			) {
				res.financeRequirementList.forEach((element) => {
					if (element.reqRelevantAttachment) {
						element.reqRelevantAttachment = JSON.parse(
							element.reqRelevantAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}
			if (res.checkedTitleField) {
				res.checkedTitleField = res.checkedTitleField.split(',');
				res.ischeckedTitleField = true;
			} else {
				res.ischeckedTitleField = false;
				res.checkedTitleField = [
					'financeRequirement',
					'clientBase',
					'clientBase_businessInfo',
					'clientBase_historyLitigation',
					'clientBase_shareholder',
					'clientBase_historicalCredit',
					'clientBase_cooperationBank',
					'clientKeyInfo',
					'controlPerson',
					'clientPersonalAssets',
					'businessCase',
					'businessCase_businessArea',
					'businessCase_businessDownstream',
					'businessCase_businessUpstream',
					'businessCase_mainSupplier',
					'businessCase_downClient',
					'businessCase_productIntroduction',
					'businessCase_description',
					'businessCase_coreCompetitivenessVista',
					'businessCase_shortBoardProblems',
					'clientFinance',
					'clientFinance_subject',
					'clientFinance_quota',
					'clientFinance_analyse',
					'taxInfo',
					'bankFlow',
					'bankFlow_flowInfo',
					'bankFlow_topTen',
					'clientCapitalize',
					'capitalizeEnterpriseCreditAttachment_attachment',
					'controlPersonAttachment_attachment',
					'personalCreditAttachment_attachment',
					'otherAttachment_attachment'
				];
			}
			this.allData = res;
		},
		isShowDialogVisible(item) {
			if (item.clientId) {
				this.pshowDialogVisible(item, true, true);
			}
		},
		replaceList(obj) {
			let index = this.schemeData.findIndex(
				(item) => item.schemeId === obj.schemeId
			);
			this.schemeData.splice(index, 1, obj);
			this.schemeObj = obj;
		},
		async changeList(data) {
			// let index = this.schemeData.findIndex(
			// 	(item) => item.schemeId == data.schemeId
			// );
			// this.$set(this.schemeData[index], 'schemeLink', data.schemeLink);
			// this.$set(
			// 	this.schemeData[index],
			// 	'schemeStatus',
			// 	data.schemeStatus
			// );
			await this.getSchemeInfoList();
			let index = this.schemeData.findIndex(
				(item) => item.schemeId == data.schemeId
			);
			if (index > -1) {
				this.schemeObj = this.schemeData[index];
			}
		},
		approvaReplaceList(obj) {
			let index = this.approvalData.findIndex(
				(item) => item.schemeApprovalId === obj.schemeApprovalId
			);
			this.approvalData.splice(index, 1, obj);
			this.approvaObj = obj;
		},
		async approvaChangeList(data) {
			await this.getSchemeApprovalInfoList();
			let index = this.approvalData.findIndex(
				(item) => item.schemeApprovalId == data.schemeApprovalId
			);
			if (index > -1) {
				this.approvaObj = this.approvalData[index];
			}
		},
		showDialogVisible(row, name, type) {
			if (type == 1) {
				this.paramsId.inquiryId = row.inquiryId;

				// this.getSensitiveClient();
			}
			if (type == 4) {
				this.schemeObj = row;

				// this.getSensitiveClient();
			}
			if (type == 5) {
				this.approvaObj = row;

				// this.getSensitiveClient();
			}
			this.paramsId.clientId = row.clientId;
			this.paramsId.dealStatus = row.dealStatus;
			this.paramsId.clientPresidentRelatedId =
				row.clientPresidentRelatedId;
			this[name] = true;

			if (type == 2) {
				this.triggerGetClientInfo();
			} else if (type == 3) {
				this.getClientInfoSensitive(row);
			}

			this.getClientListOfPresident();
		},
		async triggerGetClientInfo() {
			const res = await getClientInfo(this.paramsId);
			this.allData = res;
		},

		async getSensitiveClient() {
			const res = await getSensitiveClientInfo(this.paramsId);
			this.allData = res;
		},
		async getClientInfoSensitive(row) {
			const type = await getClientInfoSensitive({
				cooperationPresidentId:
					this.$store.state.userInfo.userVo.cooperationPresidentId,
				clientId: row.clientId,
				clientPresidentRelatedId: row.clientPresidentRelatedId,
			});
			this.privacyType = type;
			let res = {};
			if (type == 1) {
				res = await getSensitiveClientInfo(this.paramsId);
			} else if (type == 2) {
				res = await getClientInfo(this.paramsId);
			}
			if (res.clientBase.businessInformationAttachment) {
				res.clientBase.businessInformationAttachment = JSON.parse(
					res.clientBase.businessInformationAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientBase.capitalizeEnterpriseCreditAttachment) {
				res.clientBase.capitalizeEnterpriseCreditAttachment = JSON.parse(
					res.clientBase.capitalizeEnterpriseCreditAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.personsList.length) {
				res.personsList.forEach((element) => {
					if (element.controlPersonAttachment) {
						element.controlPersonAttachment = JSON.parse(
							element.controlPersonAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}
			if (res.clientBase.capitalizeAttachment) {
				res.clientBase.capitalizeAttachment = JSON.parse(
					res.clientBase.capitalizeAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.personalAssetsList.length) {
				res.personalAssetsList.forEach((element) => {
					if (element.assetAttachment) {
						element.assetAttachment = JSON.parse(
							element.assetAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}
			if (res.capitalizesList.length) {
				res.capitalizesList.forEach((element) => {
					if (element.guaranteeAttachment) {
						element.guaranteeAttachment = JSON.parse(
							element.guaranteeAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}

			if (res.clientFinanceVO.balanceSheetAttachment) {
				res.clientFinanceVO.balanceSheetAttachment = JSON.parse(
					res.clientFinanceVO.balanceSheetAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientFinanceVO.cashFlowStatementAttachment) {
				res.clientFinanceVO.cashFlowStatementAttachment = JSON.parse(
					res.clientFinanceVO.cashFlowStatementAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientFinanceVO.incomeStatementAttachment) {
				res.clientFinanceVO.incomeStatementAttachment = JSON.parse(
					res.clientFinanceVO.incomeStatementAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.clientFinanceVO.taxPaymentCertificateAttachment) {
				res.clientFinanceVO.taxPaymentCertificateAttachment =
					JSON.parse(
						res.clientFinanceVO.taxPaymentCertificateAttachment
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
			}
			if (res.clientFinanceVO.taxReturnsAttachment) {
				res.clientFinanceVO.taxReturnsAttachment = JSON.parse(
					res.clientFinanceVO.taxReturnsAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.financeRequirementList.length) {
				res.financeRequirementList.forEach((element) => {
					if (element.reqRelevantAttachment) {
						element.reqRelevantAttachment = JSON.parse(
							element.reqRelevantAttachment
						).map((item) => ({
							name: item.fileName,
							url: item.filePath,
						}));
					}
				});
			}
			if (res.otherKeyInfo.taxPaymentCertificateAttachment) {
				res.otherKeyInfo.taxPaymentCertificateAttachment = JSON.parse(
					res.otherKeyInfo.taxPaymentCertificateAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.otherKeyInfo.vatReturnAttachment) {
				res.otherKeyInfo.vatReturnAttachment = JSON.parse(
					res.otherKeyInfo.vatReturnAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.otherKeyInfo.incomeTaxReturnAttachment) {
				res.otherKeyInfo.incomeTaxReturnAttachment = JSON.parse(
					res.otherKeyInfo.incomeTaxReturnAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.otherKeyInfo.otherAttachment) {
				res.otherKeyInfo.otherAttachment = JSON.parse(
					res.otherKeyInfo.otherAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
			if (res.riskScreeningInformationList.length) {
				res.riskScreeningInformationList.forEach((item) => {
					if (!item.tryingToStudy) {
						item.tryingToStudy = '--';
					}
				});
			}
			if (res.checkedTitleField) {
				res.checkedTitleField = res.checkedTitleField.split(',');
				res.ischeckedTitleField = true;
			} else {
				res.ischeckedTitleField = false;
				res.checkedTitleField = [
					'financeRequirement',
					'clientBase',
					'clientBase_businessInfo',
					'clientBase_historyLitigation',
					'clientBase_shareholder',
					'clientBase_historicalCredit',
					'clientBase_cooperationBank',
					'clientKeyInfo',
					'controlPerson',
					'clientPersonalAssets',
					'businessCase',
					'businessCase_businessArea',
					'businessCase_businessDownstream',
					'businessCase_businessUpstream',
					'businessCase_mainSupplier',
					'businessCase_downClient',
					'businessCase_productIntroduction',
					'businessCase_description',
					'businessCase_coreCompetitivenessVista',
					'businessCase_shortBoardProblems',
					'clientFinance',
					'clientFinance_subject',
					'clientFinance_quota',
					'clientFinance_analyse',
					'taxInfo',
					'bankFlow',
					'bankFlow_flowInfo',
					'bankFlow_topTen',
					'clientCapitalize',
					'capitalizeEnterpriseCreditAttachment_attachment',
					'controlPersonAttachment_attachment',
					'personalCreditAttachment_attachment',
					'otherAttachment_attachment'
				];
			}
			this.allData = res;
		},
		async getClientInquiryList() {
			const res = await clientInquiryList(this.inquiryParams);
			if (res.returncode == 0) {
				this.inquiryData = res.list;
				this.inquiryParams.total = res.totalRows;
			}
		},
		async getClientListOfPresident() {
			const res = await clientListOfPresident(this.mycustomerParams);
			this.mycustomerData = res.list;
			this.mycustomerParams.total = res.totalRows;
		},
		async getSchemeInfoList() {
			const res = await schemeInfoList(this.schemeParams);
			this.schemeData = res.list;
			this.schemeParams.total = res.totalRows;
		},
		async getSchemeApprovalInfoList() {
			const res = await schemeApprovalInfoList(this.approvalParams);
			this.approvalData = res.list;
			this.approvalParams.total = res.totalRows;
		},
		handleSizeChange(val, params, list) {
			this[params].pageSize = val;
			this[list]();
		},
		handleCurrentChange(val, params, list) {
			this[params].pageNo = val;
			this[list]();
		},
		async getItemByType(name) {
			const res = await itemByType({
				dictionaryType: name,
			});
			this[name] = res.data.map((item) => ({
				value: item.itemValue,
				label: item.itemName,
			}));
		},
	},
	created() {
		this.getItemByType('PresidentCooperateWillingness');
		this.getItemByType('CompliesCreditProductPolicy');
		this.getItemByType('InterestedProvidingFinanceSolutions');
		this.getClientInquiryList();
		this.getClientListOfPresident();
		this.getSchemeInfoList();
		this.getSchemeApprovalInfoList();
	},
	components: {
		infoDrawer,
		customerInfoDrawer,
		inquiryDrawer,
		schemeDrawer,
		approvalDrawer,
	},
	computed: {},
	watch: {
		inquiryVisible(val) {
			if (!val) {
				this.getClientInquiryList();
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.mytable {
	width: 100%;

	height: 100%;
	overflow: auto;
}

.blue {
	color: #02a7f0;
}

.red-color {
	color: rgb(240, 2, 0);
}

.menu {
	>p {
		font-weight: 800;
	}

	margin: 10px 20px;
	box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
	margin: 10px 20px;
	overflow: hidden;
	padding: 10px;

	.menu-box {
		>span {
			display: block;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			padding-top: 40px;
			box-sizing: border-box;
			text-align: center;
			margin: 10px;
			font-size: 13px;
			transition: all 0.5s;
			border: 1px solid #ebeef5;

			&:hover {
				box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
			}
		}
	}
}

.model-box {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 0 20px;

	>div {
		box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
		width: 49.4%;
		height: 400px;
		padding: 10px;
		box-sizing: border-box;
		margin-bottom: 20px;
	}

	p {
		padding: 10px 0;
		font-weight: 800;
	}
}

::v-deep .el-badge__content.is-fixed {
	right: 0px;
}
</style>
