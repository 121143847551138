var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mytable"},[_vm._m(0),_c('div',{staticClass:"menu"},[_c('p',[_vm._v("快捷菜单")]),_c('div',{staticClass:"menu-box",staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.dialogVisible = true}}},[_c('div',[_vm._v("推荐客户给")]),_c('div',[_vm._v("悦融易")])]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/index/dataanalysis')}}},[_c('div',[_vm._v("银行流水")]),_c('div',[_vm._v("分析工具")])]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/index/financial')}}},[_c('div',[_vm._v("财务数据")]),_c('div',[_vm._v("分析工具")])])])]),_c('div',{staticClass:"model-box"},[_c('div',[_c('p',[_vm._v("悦融易推荐的10个最新客户")]),_c('el-table',{staticStyle:{"margin":"10px 0"},attrs:{"height":"77%","data":_vm.mycustomerData,"border":""}},[_c('el-table-column',{attrs:{"align":"left","label":"客户名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"padding-top":"8px"}},[(scope.row.dealStatus == 1 ||
								scope.row.dealStatus == 2
								)?_c('el-badge',{staticClass:"item",attrs:{"value":"需要我反馈"}},[_c('div',{staticClass:"blue",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showDialogVisible(
										scope.row,
										'customerDialogVisible',
										3
									)}}},[_vm._v(" "+_vm._s(scope.row.whetherSensitive == 1 ? scope.row.pdfClientName : scope.row.clientName)+" ")])]):_c('div',{staticClass:"blue",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showDialogVisible(
								scope.row,
								'customerDialogVisible',
								3
							)}}},[_vm._v(" "+_vm._s(scope.row.whetherSensitive == 1 ? scope.row.pdfClientName : scope.row.clientName)+" ")])],1)]}}])}),_c('el-table-column',{attrs:{"width":"150","align":"left","prop":"","label":"处理状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.dealStatusName)+" ")])]}}])}),_c('el-empty',{attrs:{"slot":"empty","description":"没有找到符合条件的数据"},slot:"empty"})],1)],1),_c('div',[_c('p',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',[_vm._v("待处理询单")]),_c('span',{staticStyle:{"color":"#02a7f0","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push('/index/inquiry?state=1')}}},[_vm._v(" 去反馈 ")])]),_c('el-table',{staticStyle:{"margin":"10px 0"},attrs:{"height":"77%","data":_vm.inquiryData,"border":""}},[_c('el-table-column',{attrs:{"align":"left","label":"名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"color":"#02a7f0","cursor":"pointer"},on:{"click":function($event){return _vm.showDialogVisible(
								scope.row,
								'inquiryVisible',
								1
							)}}},[_vm._v(" "+_vm._s(scope.row.pdfClientName || scope.row.clientName)+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"left","prop":"feedbackStateName","label":"状态","width":"150"}}),_c('el-empty',{attrs:{"slot":"empty","description":"没有找到符合条件的数据"},slot:"empty"})],1),_c('div',{staticStyle:{"text-align":"end"}},[_c('el-pagination',{attrs:{"current-page":_vm.inquiryParams.pageNo,"page-sizes":[5, 10, 20, 30, 50],"page-size":10,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.inquiryParams.total},on:{"size-change":function (val) { return _vm.handleSizeChange(
						val,
						'inquiryParams',
						'getClientInquiryList'
					); },"current-change":function (val) { return _vm.handleCurrentChange(
		val,
		'inquiryParams',
		'getClientInquiryList'
	); }}})],1)],1),_c('div',[_c('p',[_vm._v("客户未完结方案")]),_c('el-table',{staticStyle:{"margin":"10px 0"},attrs:{"height":"77%","data":_vm.schemeData,"border":""}},[_c('el-table-column',{attrs:{"align":"left","label":"客户名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"color":"#02a7f0","cursor":"pointer"},on:{"click":function($event){return _vm.showDialogVisible(
								scope.row,
								'schemeVisible',
								4
							)}}},[_vm._v(" "+_vm._s(scope.row.clientName)+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"left","prop":"financialProductName","label":"产品名称"}}),_c('el-table-column',{attrs:{"align":"left","prop":"schemeLinkName","label":"当前环节"}}),_c('el-empty',{attrs:{"slot":"empty","description":"没有找到符合条件的数据"},slot:"empty"})],1),_c('div',{staticStyle:{"text-align":"end"}},[_c('el-pagination',{attrs:{"current-page":_vm.schemeParams.pageNo,"page-sizes":[5, 10, 20, 30, 50],"page-size":10,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.schemeParams.total},on:{"size-change":function (val) { return _vm.handleSizeChange(
						val,
						'schemeParams',
						'getSchemeInfoList'
					); },"current-change":function (val) { return _vm.handleCurrentChange(
		val,
		'schemeParams',
		'getSchemeInfoList'
	); }}})],1)],1),_c('div',[_c('p',[_vm._v("客户未完结批复")]),_c('el-table',{staticStyle:{"margin":"10px 0"},attrs:{"height":"77%","data":_vm.approvalData,"border":""}},[_c('el-table-column',{attrs:{"align":"left","label":"客户名称"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"color":"#02a7f0","cursor":"pointer"},on:{"click":function($event){return _vm.showDialogVisible(
								scope.row,
								'approvalVisible',
								5
							)}}},[_vm._v(" "+_vm._s(scope.row.clientName)+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"left","prop":"financialProductName","label":"产品名称"}}),_c('el-table-column',{attrs:{"align":"left","prop":"nextApprovalLinkName","label":"当前环节"}}),_c('el-empty',{attrs:{"slot":"empty","description":"没有找到符合条件的数据"},slot:"empty"})],1),_c('div',{staticStyle:{"text-align":"end"}},[_c('el-pagination',{attrs:{"current-page":_vm.approvalParams.pageNo,"page-sizes":[5, 10, 20, 30, 50],"page-size":10,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.approvalParams.total},on:{"size-change":function (val) { return _vm.handleSizeChange(
						val,
						'approvalParams',
						'getSchemeInfoList'
					); },"current-change":function (val) { return _vm.handleCurrentChange(
		val,
		'approvalParams',
		'getSchemeInfoList'
	); }}})],1)],1)]),_c('infoDrawer',{attrs:{"dialogVisible":_vm.dialogVisible},on:{"closeVisible":function($event){_vm.dialogVisible = false},"update:dialogVisible":function($event){_vm.dialogVisible=$event},"update:dialog-visible":function($event){_vm.dialogVisible=$event}}}),_c('publicInfoDrawer',{attrs:{"allData":_vm.allData,"privacyType":_vm.privacyType,"dialogVisible":_vm.publicDialogVisible,"paramsId":_vm.paramsId},on:{"update:dialogVisible":function($event){_vm.publicDialogVisible=$event},"update:dialog-visible":function($event){_vm.publicDialogVisible=$event},"closeVisible":function($event){_vm.publicDialogVisible = false}}}),_c('inquiryDrawer',{attrs:{"inquiryVisible":_vm.inquiryVisible,"paramsId":_vm.paramsId},on:{"closeVisible":function($event){_vm.inquiryVisible = false},"isShowDialogVisible":_vm.isShowDialogVisible}}),_c('customerInfoDrawer',{attrs:{"PresidentCooperateWillingness":_vm.PresidentCooperateWillingness,"CompliesCreditProductPolicy":_vm.CompliesCreditProductPolicy,"InterestedProvidingFinanceSolutions":_vm.InterestedProvidingFinanceSolutions,"allData":_vm.allData,"dialogVisible":_vm.customerDialogVisible,"paramsId":_vm.paramsId,"privacyType":_vm.privacyType},on:{"update:dialogVisible":function($event){_vm.customerDialogVisible=$event},"update:dialog-visible":function($event){_vm.customerDialogVisible=$event},"closeVisible":function () {
			_vm.customerDialogVisible = false;
			_vm.getClientListOfPresident();
		}}}),_c('schemeDrawer',{attrs:{"schemeVisible":_vm.schemeVisible,"item":_vm.schemeObj},on:{"changeList":_vm.changeList,"replaceList":_vm.replaceList,"closeVisible":function($event){_vm.schemeVisible = false},"update:schemeVisible":function($event){_vm.schemeVisible=$event},"update:scheme-visible":function($event){_vm.schemeVisible=$event}}}),_c('approvalDrawer',{attrs:{"approvalVisible":_vm.approvalVisible,"item":_vm.approvaObj},on:{"changeList":_vm.approvaChangeList,"replaceList":_vm.approvaReplaceList,"closeVisible":function($event){_vm.approvalVisible = false},"update:approvalVisible":function($event){_vm.approvalVisible=$event},"update:approval-visible":function($event){_vm.approvalVisible=$event}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-title"},[_c('p',[_vm._v("首页")])])}]

export { render, staticRenderFns }